import React, { useState, Fragment } from "react";
import Loadable from "@loadable/component";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
// import SvgIcon from "../../common/SvgIcon";
import Head from "./Head";
import * as S from "./styles";
// import { Route, Link } from "@reach/router";
// import { Router } from "@reach/router";

const SvgIcon = Loadable(() => import("../../common/SvgIcon"));
const Button = Loadable(() => import("../../common/Button"));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <Fragment>
        <Head />
        <S.CustomNavLinkSmall  onClick={() => (window.location.href = "/")}>
          <S.Span>{t("Home")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("#")}>
          <S.Span>{t("Blog")}</S.Span>
        </S.CustomNavLinkSmall>

        {/* <S.CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <S.Span>{t("About")}</S.Span>
        </S.CustomNavLinkSmall> */}

        <S.CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => (window.location.href = "Features")}
        >
          <S.Span>
            <Button>{t("Features")}</Button>
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="evnew.svg" />
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);

// *******************************************************************************************************

// import React, { useState, Fragment } from "react";
// import Loadable from "@loadable/component";
// import { Row, Col, Drawer } from "antd";
// import { CSSTransition } from "react-transition-group";
// import { withTranslation } from "react-i18next";
// // import SvgIcon from "../../common/SvgIcon";
// // import Head from "./Head";
// import * as S from "./styles";

// const SvgIcon = Loadable(() => import("../../common/SvgIcon"));
// const Button = Loadable(() => import("../../common/Button"));

// const SecHeader = ({ t }) => {
//   const [isNavVisible] = useState(false);
//   const [isSmallScreen] = useState(false);
//   const [visible, setVisibility] = useState(false);

//   const showDrawer = () => {
//     setVisibility(!visible);
//   };

//   const onClose = () => {
//     setVisibility(!visible);
//   };

//   const MenuItem = () => {
//     // const scrollTo = (id) => {
//     //   const element = document.getElementById(id);
//     //   element.scrollIntoView({
//     //     behavior: "smooth",
//     //   });
//     //   setVisibility(false);
//     // };
//     return (
//       <Fragment>
//         <Head />

//         <S.CustomNavLinkSmall onClick={() => (window.location.href = "/")}>
//           <S.Span>{t("Home")}</S.Span>
//         </S.CustomNavLinkSmall>

//         <S.CustomNavLinkSmall onClick={() => (window.location.href = "#")}>
//           <S.Span>{t("Blog")}</S.Span>
//         </S.CustomNavLinkSmall>

//         <S.CustomNavLinkSmall
//           style={{ width: "180px" }}
//           onClick={() => (window.location.href = "/Features")}
//         >
//           <S.Span>
//             <Button>{t("Features")}</Button>
//           </S.Span>
//         </S.CustomNavLinkSmall>
//       </Fragment>
//     );
//   };

//   return (
//     <S.Header>
//       <S.Container>
//         <Row type="flex" justify="space-between" gutter={20}>
//           <S.LogoContainer to="/" aria-label="homepage">
//             <SvgIcon
//               src="evnew.svg"
//               onClick={() => (window.location.href = "/")}
//             />
//           </S.LogoContainer>
//           <S.NotHidden>
//             <MenuItem />
//           </S.NotHidden>
//           <S.Burger onClick={showDrawer}>
//             <S.Outline />
//           </S.Burger>
//         </Row>
//         <CSSTransition
//           in={!isSmallScreen || isNavVisible}
//           timeout={350}
//           classNames="NavAnimation"
//           unmountOnExit
//         >
//           <Drawer closable={false} visible={visible} onClose={onClose}>
//             <Col style={{ marginBottom: "2.5rem" }}>
//               <S.Label onClick={onClose}>
//                 <Col span={12}>
//                   <S.Menu>Menu</S.Menu>
//                 </Col>
//                 <Col span={12}>
//                   <S.Outline padding="true" />
//                 </Col>
//               </S.Label>
//             </Col>
//             <MenuItem />
//           </Drawer>
//         </CSSTransition>
//       </S.Container>
//     </S.Header>
//   );
// };

// export default withTranslation()(SecHeader);
